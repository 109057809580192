import React, { useState } from 'react';

import sanityClient from '../client';

const Contact = () => {
    const [name,setName]=useState('');
    const [email,setEmail]=useState('');
    const [text,setText]=useState('');

    const handleInputChange=  (e)=>{
        const {value,name}=e.target;
        if(name==='name'){
            setName(value)
        }else if(name==='email'){
            setEmail(value)
        }else if(name==='textarea'){
            setText(value);
        }
    }

    const handleFormSubmit=(e)=>{
        e.preventDefault();
        sanityClient.create({
            _type: 'contact',
            name,
            email,text
        }).then((res)=>{
            console.log(res)
        }).catch((err)=>{
            console.log(err)
        })
    }
    return (  
        <main>
            <h1>Kontakt</h1>
            <form onSubmit={handleFormSubmit}>
                <div>
                    <input type="text" placeholder='Imię i naziwsko' name='name' onChange={handleInputChange} value={name}/>
                </div>
                <div>
                    <input type="text" placeholder='email' name='email'  onChange={handleInputChange} value={email}/>
                </div>
                <div>
                    <textarea  id="" cols="30" rows="10" placeholder='treść' name='textarea'  onChange={handleInputChange} value={text}></textarea>
                </div>
                <input type="submit" value="Wyślij" />
            </form>
        </main>
    );
}
 
export default Contact;