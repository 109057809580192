import React, { useEffect, useState } from 'react';
import sanityClient from '../client';
import {Link} from 'react-router-dom';

const Projects = () => {
    const [projects,setProjects]=useState([]);

    useEffect(()=>{
        sanityClient.fetch(`*[_type=='projects']{
            name,
            content,
            slug,
            image{
                asset->{
                    url
                }
            }
        }`).then((data)=>{
            console.log(data)
            setProjects(data)
        }).catch((err)=>{
            console.log(err)
        })
    },[])
    return (  
        <main>
            <h1>Projekty</h1>
            <ul>
                {projects.map((item,i)=>(
                    <li key={i}>
                        <Link to={`/projekt/${item.slug.current}`}>
                        <img src={item.image.asset.url} alt="" style={{width:'100px'}}/>
                        <h3>{item.name}</h3>
                        </Link>
                    </li>
                ))}
            </ul>
        </main>
    );
}
 
export default Projects;