import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return ( 
        <nav>
            <ul>
                <li>
                    <Link to='/'>Strona główna</Link>
                </li>
                <li>
                    <Link to='/projekty'>Nasze projekty</Link>
                </li>
                <li>
                    <Link to='/kontakt'>Kontakt</Link>
                </li>
            </ul>
        </nav>
     );
}
 
export default Nav;