import React, { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../client';

const Project = () => {
    const params = useParams();
    const [project,setProject]=useState(null);

    useEffect(()=>{
        sanityClient.fetch(`*[slug.current=='${params.slug}']{
            content,
            name,
            image{
                asset->{
                    url
                }
            }
        }`).then(data=>{
            console.log(data)
            setProject(data[0])
        }).catch((err)=>{
            console.log(err)
        })
    },[])
    return ( 
        <main>
            {
                project!==null?(
                    <div>

                    <h1>{project.name}</h1>
                    <img src={project.image.asset.url} alt={project.name} style={{width:'100px'}}/>
                    <div>{
                    project.content.map((item,i)=>{
                        return <div key={i}>{
                            item.children.map((item,i)=>{
                                let styleToApply={};
                                if(item.marks.includes('strong')){
                                    styleToApply.fontWeight='bold';
                                }
                                if(item.marks.includes('em')){
                                    styleToApply.fontStyle='italic';
                                }
                                return <span key={i} style={styleToApply}>{item.text}</span> 
                            })}
                        </div>
                    })
                    }</div>                          
                    </div>
                ):''
            }
        </main>
     );
}
 
export default Project;