import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Home from './pages/Home';
import Nav from './components/Nav';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Project from './pages/Project';

const App = () => {
  // const [posts,setPosts]=useState([]);

  // useEffect(()=>{
  //   sanityClient.fetch(`*[_type=="post"]{
  //       title,
  //       slug,
  //       mainImage{
  //         asset->{
  //           _id,
  //           url
  //         }
  //       },
  //       body
  //     }`).then((data)=>{
  //       console.log(data)
  //       setPosts(data);
  //     }).catch(err=>console.log(err))
  // },[])
  return ( 
 <Router>
  <Nav/>
  <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/projekty' element={<Projects/>}/>
    <Route path='/kontakt' element={<Contact/>}/>
    <Route path='/projekt/:slug' element={<Project/>}/>
  </Routes>
 </Router>
  )
}
 
export default App;